<template>
  <div class="page-header">
    <div class="container-fluid">
      <breadcrumbs></breadcrumbs>
      <div class="d-lg-flex align-items-end justify-content-between">
        <div>
          <h1 class="page-title">{{ title }}</h1>
          <h3 class="page-title-sub">
            <router-link to="/pages/applications">{{ subTitle }}</router-link>
          </h3>
          <ul class="list-inline mb-1">
            <li class="list-inline-item">
              <span class="text-muted">Package:</span>
              <strong>{{ packageName }}</strong>
            </li>
            <li class="list-inline-item">
              <span class="text-muted">Status:</span>
              <strong> {{ status }} </strong>
            </li>
          </ul>
        </div>
        <ul class="nav mt-3-md mt-lg-1 d-print-none" v-if="showActions">
          <li v-if="showMenu" class="nav-item">
            <button
              class="btn btn-md btn-link pl-0 pl-lg-2 pr-2 dropdown-toggle"
              id="managePackage"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Manage Package
            </button>
            <div class="dropdown-menu" aria-labelledby="managePackage">
              <a class="dropdown-item" href="javascript:void(0)"
                >Download Package</a
              >
              <a class="dropdown-item" href="javascript:void(0)"
                >Delete Package</a
              >
            </div>
          </li>
          <li v-if="showEsign" class="nav-item mr-1">
            <a
              v-if="!isEsigned"
              class="btn btn-md btn-secondary px-2"
              href="javascript:void(0)"
              >{{ esignLabel }}</a
            >
            <span
              v-if="isEsigned"
              class="h-100 d-flex align-items-center px-1 text-base"
            >
              <svg class="icon icon-check text-success mr-1">
                <use xlink:href="/icons/symbol-defs.svg#icon-check"></use>
              </svg>
              All E-signed
            </span>
          </li>
          <li v-if="showValidate" class="nav-item mr-1">
            <a class="btn btn-md btn-secondary px-2" href="javascript:void(0)"
              >Validate Package</a
            >
          </li>
          <li v-if="showDownload" class="nav-item mr-1">
            <a class="btn btn-md btn-secondary px-2" href="javascript:void(0)"
              >Download Package</a
            >
          </li>
          <li class="nav-item">
            <a :class="classObject" href="javascript:void(0)">
              {{ actionLabel }}
              <svg v-if="showActionIcon" class="icon icon-arrow_cta">
                <use xlink:href="/icons/symbol-defs.svg#icon-arrow_cta"></use>
              </svg>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    subTitle: String,
    showEsign: {
      type: Boolean,
      default: true,
    },
    esignLabel: {
      type: String,
      default: 'E-sign',
    },
    isEsigned: {
      type: Boolean,
      default: false,
    },
    showValidate: {
      type: Boolean,
      default: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    packageName: {
      type: String,
      default: 'Land Title',
    },
    status: {
      type: String,
      default: 'Unsubmitted',
    },
    actionLabel: {
      type: String,
      default: 'Prepare Submission',
    },
    actionDisabled: {
      type: Boolean,
      default: false,
    },
    actionButtonClass: {
      type: String,
      default: 'primary',
    },
    showActionIcon: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    classObject: function () {
      return {
        'btn-primary': this.actionButtonClass === 'primary',
        'btn-secondary': this.actionButtonClass === 'secondary',
        'btn btn-md btn-primary mt-1 mt-sm-0 px-2': true,
        disabled: this.actionDisabled,
        // this.actionButtonClass: true,
      };
    },
  },
};
</script>
